import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable, of } from "rxjs";
import { OcpiService } from "../services/ocpi.service";


@Injectable({
    providedIn: 'root'
})
export class LocationGuard implements CanActivate {

    constructor(private ocpiService: OcpiService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        const location = this.ocpiService.getSelectedLocation();
        if (location) {
            return of(true);
        } else {
            this.router.navigate(['tabs']);
        }
    }
}