import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders, HttpResponse, HttpErrorResponse} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, flatMap, map, mergeMap } from 'rxjs/operators';
import { TokenService } from './token.service';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  loginCalledAlready = false;

  constructor(
    private router: Router,
    public tokenService: TokenService,
    private userService: UserService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.url.indexOf("/send?") > -1 || req.url.indexOf("/verify") > -1 || req.url.indexOf("/expiry-time") > -1) {
      return next.handle(req);
    }

    let authReq;
    if (req.url.indexOf("refresh_token=") > -1) {
     authReq = req.clone({
        headers: new HttpHeaders({
          "Authorization": `Basic bW9iaWxlOnBpbg==`,
        })
      });
    } else {
     authReq = req.clone({
        headers: new HttpHeaders({
          "Authorization": `Bearer ${this.tokenService.getToken()}`,
          //"X-TenantID": `${localStorage.getItem("X-TenantID")}`
        })
      });
    }
    return next.handle(authReq).pipe(catchError(error => {
      if(error.status === 401) {
        let currentUrl = this.router.url;
        const isLogoutPath = ['/', '/login', '/signup', '/phone-number-enter', '/tou', '/privacy'].indexOf(currentUrl) > -1 || currentUrl.indexOf('/otp?') > -1;
        if (!this.loginCalledAlready) {
          this.loginCalledAlready = true;
          return this.tokenService.getTokenFromRefreshToken().pipe(flatMap((data: any) => {
              this.tokenService.saveToken(data.access_token);
              this.tokenService.saveRefreshToken(data.refresh_token);

              req = req.clone({
                headers: new HttpHeaders({
                  "Authorization": `Bearer ${data.access_token}`,
                })
              });
              return next.handle(req).pipe(catchError(error => {
                this.userService.logout();
                this.loginCalledAlready = false;
                return null;
              }), map(resp => { 
                this.loginCalledAlready = false;
                return resp; 
              }));
            
          } ));
        } else {
          this.loginCalledAlready = false;
        }
      }
      return throwError(error);
    }), map((event: HttpEvent<any>) => event));
  }
}

