import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class AppConfig {
   public static API_URL = environment.apiUrl;

   public static REFRESH_OTP_TIME_SEC = 60;

   public static  DRIVER_URL = AppConfig.API_URL + '/api/user/v1';
   public static  USER_URL = AppConfig.API_URL + '/api/user/v1/users/';
   public static  ADMIN_URL = AppConfig.API_URL + '/api/user/v1/';
   public static  OCPI_URL = AppConfig.API_URL + '/api/ocpi/v1/';
   public static  BILLING_URL = AppConfig.API_URL + '/api/billing/v1/';
   public static  REIVIEW_URL = AppConfig.API_URL + '/api/review/v1/';
   public static  SUPPORT_URL = AppConfig.API_URL + '/api/support/v1/';
   public static  IMAGE_UPLOAD_URL = AppConfig.API_URL + '/api/cms/v1/';
   public static  NOTIFICATIONS_URL = AppConfig.API_URL + '/api/notification/v1/';
   public static  EMSP_CONFIG_URL = AppConfig.API_URL + '/api/user/v1/emsp/config/e925731ff7b70acde478591f1327bd1b';
  // public static  TOKEN_URL = AppConfig.API_URL + '/api/user/oauth/token/refresh';
   public static  TOKEN_URL = AppConfig.API_URL + '/auth/oauth/token?grant_type=refresh_token&refresh_token=';
   public static SOCIAL_LOGIN_URL = AppConfig.API_URL + '/auth/oauth2/authorize/google?redirect_uri=http://localhost:8100/social-login';

   //public static SOCIAL_LOGIN_URL = AppConfig.API_URL + '/auth/oauth2/authorize/google?redirect_uri=https://dev.fich.digital/social-login';
 //  public static  IMAGE_UPLOAD_URL = AppConfig.API_URL + '/api/cms/v1/';


   // public static firebasConfig = {
   //    apiKey: "AIzaSyDXf7SVxZy_E33xDQAVx63BQGVc4oUDihk",
   //    authDomain: "evnnovator-dd024.firebaseapp.com",
   //    databaseURL: "https://evnnovator-dd024-default-rtdb.asia-southeast1.firebasedatabase.app",
   //    projectId: "evnnovator-dd024",
   //    storageBucket: "evnnovator-dd024.appspot.com",
   //    messagingSenderId: "1084277787630",
   //    appId: "1:1084277787630:web:7d61a08847f45c3c3edcff",
   //    measurementId: "G-N45V5BKQC9"
   //  };
}