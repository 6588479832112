import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { ToastController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { ImageUploadService } from './services/image-upload.service';
import { OcpiService } from './services/ocpi.service';
import { TrariffService } from './services/tariff.service';
import { UserService } from './services/user.service';
import { TokenService } from './shared/token.service';
@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy {
    public appPages: any[] = [
        {
            title: 'My Profile',
            url: '/profile'
        },
        {
            title: 'Activie Sessions',
            url: ''
        },
        {
            title: 'My Vehicles',
            url: '/my-vehicle'
        },
        {
            title: 'Wallet',
            url: '/wallet'
        },
        {
            title: 'Favorites',
            url: '/tabs/tab3'
        },
        {
            title: 'My Usage',
            url: '/my-usage'
        },
        {
            title: 'Charging History',
            url: '/history'
        },
        {
            title: 'FAQ',
            url: '/faq'
        },
        {
            title: 'Support',
            url: '/support'
        },
        {
            title: 'Terms and Conditions',
            url: '/tou'
        },
        {
            title: 'Privacy Policy',
            url: '/privacy'
        },
        {
            title: 'Logout',
            url: ''
        }

    ];

    currentUser: any;
    unsubcribe = new Subject<void>();
    profileImg;

    constructor(private router: Router, private userService: UserService, private imageUploadService: ImageUploadService, 
        private toastController: ToastController, private ocpiService: OcpiService, private tariffService: TrariffService,  private swUpdate: SwUpdate) {
        
        this.swUpdate.available.subscribe(() => {
            console.log("========page reload============");
            window.location.reload();
        });
        
        this.userService.currentUser$().pipe(takeUntil(this.unsubcribe)).subscribe(user => {
            this.currentUser = user;
            if (user) {
                this.imageUploadService.getImage('DRIVER', user.id);
                if (user.customer_type === 'CORPORATE') {
                    this.appPages = this.appPages.filter(item => item.title !== 'Wallet');
                }
            }
        });

        this.imageUploadService.geimageChangesSubject$().pipe(takeUntil(this.unsubcribe)).subscribe(resp => {
            if (resp && resp.category === 'DRVIER') {
                this.profileImg = resp.url;
            }
        });
    }
    ngOnDestroy(): void {
        this.unsubcribe.next();
        this.unsubcribe.complete();
    }

    logout(): void {
        this.userService.logout();
    }

    showToast() {
        setTimeout(async () => {
            const toast = await this.toastController.create({
                message: "Currently no charging session is going on. Please check charging history.",
                position: 'top',
                color: 'black',
                duration: 2000
            });
            toast.present();
        });
    }

    navigate(item) {
        if (item.title === 'Activie Sessions') {
            this.ocpiService.getSessionUpdate().subscribe((resp) => {
                if (resp && resp.data && resp.data.status === 'ACTIVE') {
                    this.ocpiService.goToActiveChargingSession(resp.data); 
                } else {
                    this.showToast();
                }
            });
        } else if (item.title === 'Logout') {
            this.logout();
        } else {
            this.router.navigate([item.url]);
        }
    }


}
