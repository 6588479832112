import { Injectable } from "@angular/core";
import { Vehicle } from "../models/vehicle";


@Injectable({
    providedIn: 'root'
})
export class TrariffService {

    dayArray = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"];

    // sampleTariff = {
    //     "id": "11",
    //     "currency": "INR",
    //     "tariff_alt_url": "https://company.com/tariffs/11",
    //     "elements": [{
    //         "price_components": [{
    //             "type": "FLAT",
    //             "price": 2.50,
    //             "step_size": 1
    //         }]
    //     }, {
    //         "price_components": [{
    //             "type": "TIME",
    //             "price": 100.00,
    //             "step_size": 900
    //         }],
    //         "restrictions": {
    //             "max_power": 32.00
    //         }
    //     }, {
    //         "price_components": [{
    //             "type": "TIME",
    //             "price": 200.00,
    //             "step_size": 600
    //         }],
    //         "restrictions": {
    //             "min_power": 32.00,
    //             "day_of_week": ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY"]
    //         }
    //     }, {
    //         "price_components": [{
    //             "type": "TIME",
    //             "price": 125.00,
    //             "step_size": 600
    //         }],
    //         "restrictions": {
    //             "min_power": 32.00,
    //             "day_of_week": ["SATURDAY", "SUNDAY"]
    //         }
    //     }, {
    //         "price_components": [{
    //             "type": "PARKING_TIME",
    //             "price": 500.00,
    //             "step_size": 300
    //         }],
    //         "restrictions": {
    //             "start_time": "09:00",
    //             "end_time": "18:00",
    //             "day_of_week": ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY"]
    //         }
    //     }, {
    //         "price_components": [{
    //             "type": "PARKING_TIME",
    //             "price": 600.00,
    //             "step_size": 300
    //         }],
    //         "restrictions": {
    //             "start_time": "10:00",
    //             "end_time": "17:00",
    //             "day_of_week": ["SATURDAY", "SUNDAY"]
    //         }
    //     }],
    //     "last_updated": "2015-06-29T20:39:09Z"
    // }

    

    getSampleTariff() {
        return this.sampleTariff;
    }

    calculatePrice() {
        let price = this.getPrice(this.sampleTariff, 40, 50);
        let time = this.getTime(this.sampleTariff, 40, 63);
    }

    getElements(tariff: any, power: number) {
        let flatElement: any;
        let parkingElement: any;
        let chargingElement: any;

        const currentDay = new Date().getDay();
        const dayText = this.dayArray[currentDay];

        (tariff && tariff.elements || []).forEach(element => {
            if (element.price_components[0].type === 'FLAT') {
                flatElement = element;
            }

            if (element.price_components[0].type === 'PARKING_TIME' && !parkingElement) {
                if (element.restrictions) {
                    if (element.restrictions.day_of_week && element.restrictions.day_of_week.indexOf(dayText) > -1) {
                        parkingElement = element;
                    }
                } else {
                    parkingElement = element;
                }
            }

            if (element.price_components[0].type === 'TIME' && !chargingElement) {
                if (element.restrictions) {
                    if (element.restrictions) {
                        if (+element.restrictions.max_power) {
                            if (power <= +element.restrictions.max_power) {
                                if (element.restrictions.day_of_week) {
                                    if (element.restrictions.day_of_week.indexOf(dayText) > -1) {
                                        chargingElement = element;
                                    }
                                } else {
                                    chargingElement = element;
                                }
                            }
                        }

                        if (!chargingElement && !element.restrictions.max_power && +element.restrictions.min_power) {
                            if (power >= +element.restrictions.min_power) {
                                if (element.restrictions.day_of_week) {
                                    if (element.restrictions.day_of_week.indexOf(dayText) > -1) {
                                        chargingElement = element;
                                    }
                                } else {
                                    chargingElement = element;
                                }
                            }
                        }

                        if (!chargingElement && !element.restrictions.max_power && !element.restrictions.min_power) {
                            if (element.restrictions.day_of_week) {
                                if (element.restrictions.day_of_week.indexOf(dayText) > -1) {
                                    chargingElement = element;
                                }
                            } else {
                                chargingElement = element;
                            }
                        }
                    }
                } else {
                    chargingElement = element;
                }
            }
        });

        return { flatElement, parkingElement, chargingElement };

    }

    getPrice(tariff: any, power: number, timeInMinutes: number) {

        let elements = this.getElements(tariff, power);

        let flatElement = elements.flatElement;
        let parkingElement = elements.parkingElement;
        let chargingElement = elements.chargingElement;

        let amount = 0;
        let parkingFactor = 0;

        if (flatElement) {
            amount += +flatElement.price_components[0].price;
        }

        let totalTime = timeInMinutes * 60;

        if (chargingElement) {
            let totalSteps = 0;
            for (let time = 0; time < totalTime; time = time + chargingElement.price_components[0].step_size) {
                totalSteps += 1;
                amount += +chargingElement.price_components[0].price;
            }

            if (parkingElement) {
                parkingFactor = parkingElement.price_components[0].step_size / chargingElement.price_components[0].step_size;
                let totalParakingSlots = Math.floor(totalSteps / parkingFactor);
                if (totalSteps % parkingFactor) {
                    totalParakingSlots += 1;
                }
                amount = amount + (totalParakingSlots * +parkingElement.price_components[0].price);
            }
        }
        if(amount) {
            amount = +amount.toFixed(2);
        }
        return amount;
    }

    getTime(tariff: any, power: number, price: number) {

        let elements = this.getElements(tariff, power);

        const flatElement = elements.flatElement;
        const parkingElement = elements.parkingElement;
        const chargingElement = elements.chargingElement;

        let time = 0;
        let amount = price;
        let parkingCharges = 0;

        if (flatElement) {
            amount = price - +flatElement.price_components[0].price;
        }

        if (parkingElement) {
            parkingCharges = (chargingElement.price_components[0].step_size / parkingElement.price_components[0].step_size) * parkingElement.price_components[0].price;
        }

        if (chargingElement) {
            let chargesForStepSize = parkingCharges + +chargingElement.price_components[0].price;

            while (chargesForStepSize < amount) {
                time += chargingElement.price_components[0].step_size;
                amount -= chargesForStepSize;
            }
        }

        return Math.floor(time / 60);
    }

    isValidComponetByTime(restrictions) {
        if(restrictions.start_time && restrictions.end_time) {
            let time = restrictions.start_time.split(":");
            const startHours = time[0] || '0';
            //const startMins = time[1] || '0';

            time = restrictions.end_time.split(":");
            const endHours = time[0] || '0';
           // const endMins = time[1] || '0';

            let currentHours = new Date().getHours();
           // let currentMis = new Date().getMinutes();

            if(currentHours >= +startHours && currentHours <= endHours) {
                return true;
            }
        } else {
            return true;
        }

        return false;
    }

    isValidComponetByDayAndTime(restrictions) {
        const currentDay = new Date().getDay();
        const dayText = this.dayArray[currentDay];

        if (restrictions.day_of_week) {
            if (restrictions.day_of_week.indexOf(dayText) > -1) {
                return this.isValidComponetByTime(restrictions);
            }
        } else {
            return this.isValidComponetByTime(restrictions);
        }
        return false;
    }

    getChargingElement(tariff: any, power: number) {
        
        let chargingElement: any;

        (tariff && tariff.elements || []).forEach(element => {

            (element.price_components || []).forEach(component => {
               

                // {
                //     "type": "ENERGY",
                //     "price": 0.25,
                //     "vat": 10.0,
                //     "step_size": 1
                //   }
                if (component.type === 'ENERGY' && !chargingElement) {
                    chargingElement = component;
                }

                if (component.type === 'TIME' && !chargingElement) {
                    if (element.restrictions ) {
                        if (element.restrictions.reservation) {
                            return false;
                        }

                        if (+element.restrictions.max_power) {
                            if (power <= +element.restrictions.max_power) {
                                if (this.isValidComponetByDayAndTime(element.restrictions)) {
                                    chargingElement = component;
                                }
                            }
                        }

                        if (!chargingElement && !element.restrictions.max_power && +element.restrictions.min_power) {
                            if (power >= +element.restrictions.min_power) {
                                if (this.isValidComponetByDayAndTime(element.restrictions)) {
                                    chargingElement = component;
                                }
                            }
                        }

                        if (!chargingElement && !element.restrictions.max_power && !element.restrictions.min_power) {
                            if (this.isValidComponetByDayAndTime(element.restrictions)) {
                                chargingElement = component;
                            }
                        }

                    } else {
                        chargingElement = component;
                    }
                }

            });

        });

        return  chargingElement;

    }

    sampleTariff = {
        "country_code": "DE",
        "party_id": "ALL",
        "id": "20",
        "currency": "EUR",
        "elements": [{
          "price_components": [{
            "type": "TIME",
            "price": 500.00,
            "vat": 20.0,
            "step_size": 60
          }],
          "restrictions": {
            "reservation": "RESERVATION"
          }
        }, {
          "price_components": [{
            "type": "FLAT",
            "price": 50.00,
            "vat": 20.0,
            "step_size": 1
          }, {
            "type": "ENERGY",
            "price": 25.00,
            "vat": 10.0,
            "step_size": 1
          }]
        }],
        "last_updated": "2019-02-03T17:00:11Z"
      }

    getPowerFactor(powerType: string) {
        let powerFactor = 1;

        if (powerType === 'AC_3_PHASE') {
            powerFactor = 0.98 * 1.73;
        } else if (powerType === 'AC_1_PHASE') {
            powerFactor = 0.98;
        }

        return powerFactor;
    }

    getPower(connector, vehicle) {
        let powerInWatts = connector.max_electric_power || (connector.amperage * connector.voltage * this.getPowerFactor(connector.power_type));
        let power = +(powerInWatts / 1000).toFixed(1);

        if (vehicle) {
            if (connector.power_type === 'DC' && power > vehicle.max_dc_intake) {
                power = vehicle.max_dc_intake;
            } else if(connector.power_type !== 'DC' && power > vehicle.onboard_charger_capacity) {
                power = vehicle.onboard_charger_capacity;
            }
        }

        return power;
    }

    getEstimateForHour(connector: any, vehicle: Vehicle) {

        const  estimate = new Estimate;
        estimate.power = this.getPower(connector, vehicle);
        estimate.time = 60;
        let element = this.getChargingElement(connector.tariff, estimate.power);

        if (element) {
            if (element.type === 'ENERGY') {
                estimate.price = +(estimate.power * +element.price).toFixed(2);
            }
    
            if (element.type === 'TIME') {
                estimate.price = +element.price;
            }
    
            estimate.element = element;
        }

        if (vehicle) {
            estimate.maxBattery = +vehicle.battery_capacity;
            estimate.maxRange = vehicle.range;

            estimate.rangeHr = +((vehicle.range * 1 * estimate.power * 0.9) / +vehicle.battery_capacity).toFixed(2);
        }
        return estimate;


    }

}

export class Estimate {
    price?: number;
    time?: number;
    power?: number;
    range?: number;
    element?: any;
    maxRange?: number;
    maxBattery?: number;
    rangeHr?: number;
}