import { Injectable } from '@angular/core';
import { PopoverController, ToastController } from '@ionic/angular';
import { AddVehiclePopupComponent } from '../components/alerts/add-vehicle-popup/add-vehicle-popup.component';

@Injectable({
  providedIn: 'root'
})
export class AppCommonService {

  private signInFlow = false;
  private showAddVehicle = false;
  private fleetSignInFlow = false;

  constructor(private popoverCtrl: PopoverController, private toastController: ToastController) { }

  isFleetSignIn() {
    return this.fleetSignInFlow;
  }

  setFeetSingInFlow(val) {
    this.showAddVehicle = val;
    this.fleetSignInFlow = val;
  }

  isSignInFlow() {
    return this.signInFlow;
  }

  setSignInFlow(isSignInFlow = false) {
    if (this.signInFlow && !isSignInFlow) {
      this.showAddVehicle = true;
    }
    this.signInFlow = isSignInFlow; 
  }

  showAddVehiclePopup() {
    if (this.showAddVehicle) {
      setTimeout(async () => {
        const popover = await this.popoverCtrl.create({
          component: AddVehiclePopupComponent,
          cssClass: 'alert-popover-width',
          translucent: true
        });
        await popover.present();
      }, 1000);
      this.showAddVehicle = false;
    }
  }

  async showError(message) {
    const toast = await this.toastController.create({
      message: message,
      position: 'top',
      color: 'black',
      duration: 2000
    });
    toast.present();
}

}
