import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LocationSerarchParams } from '../models/cpo';
import { AppConfig } from '../shared/app-config';
import { TrariffService } from './tariff.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class OcpiService {


  selectedLocation: any;
  selectedConnector: any;

  lastSessionId: string;
  wishList = [];

  searchCritera: LocationSerarchParams;

  searchFilterSubject: BehaviorSubject<LocationSerarchParams> = new BehaviorSubject({} as LocationSerarchParams);
  wishListSubject: BehaviorSubject<any> = new BehaviorSubject([]);

  stopChargingSubject: BehaviorSubject<any> = new BehaviorSubject(false);

  activeSessionData: any;


  constructor(private http: HttpClient, private userService: UserService, private tariffService: TrariffService, private router: Router) { }

  searchCPOs(body: LocationSerarchParams) {

    let searchParamText = `langitude=${body.longitude}&latitude=${body.latitude}&page_no=${body.page || '0'}&page_size=${body.size || '1000'}`;

    if(body.distance) {
      searchParamText = `${searchParamText}&distance=${body.distance}`;
    }

    if(body.status) {
      searchParamText = `${searchParamText}&status=${body.status}`;
    }

    if(body.power_type) {
      searchParamText = `${searchParamText}&power_type=${body.power_type}`;
    }

    if(body.standard) {
      searchParamText = `${searchParamText}&standard=${body.standard}`;
    }

    if(body.search_text) {
      searchParamText = `${searchParamText}&search-text=${body.search_text}`;
    }

    return this.http.get<any>(`${AppConfig.OCPI_URL}locations/search?&${searchParamText}&apply_tariff_restrictions=true`).pipe(map(resp => {
      ((resp && resp.content) || []).forEach(location => {
        location.isWishListItem = !!this.wishList.find(item => item.id === location.id);
      });;
      return resp;
    }));
  }

  getSerchFilter$() {
    return this.searchFilterSubject.asObservable();
  }

  getCurrentSearchCriteria() {
    return this.searchCritera;
  }
  
  updateSearchFilter(criteria: LocationSerarchParams) {
    this.searchCritera = criteria;
    this.searchFilterSubject.next(criteria);
  }

  getUid(locationId) {
    return this.http.get<any>(`${AppConfig.OCPI_URL}whitelist/driver/${this.userService.getUser().id}/location/${locationId}`);
  }

  getLocationDetails(locationId) {
    return this.http.get<any>(`${AppConfig.OCPI_URL}locations/${locationId}?apply_tariff_restrictions=true`);
  }

  getWishListSubject$() {
    return this.wishListSubject.asObservable();
  }

  getStopChargingSubject$() {
    return this.stopChargingSubject.asObservable();
  }

  stopRefreshPolling() {
    this.stopChargingSubject.next(true);
  }

  fetchWishList(latitude, longitude) {
    return this.http.get<any>(`${AppConfig.OCPI_URL}wishlist/${this.userService.getUser().id}?latitude=${latitude}&longitude=${longitude}`).pipe(map(resp => {
      this.wishList = (resp && resp.data && resp.data.locations) || [];
      this.wishList.forEach(location => {
        location.isWishListItem = true;
      });
      this.wishListSubject.next(this.wishList);
      return resp;
    }));
  }

  addToWishList(location) {
    return this.http.post<any>(`${AppConfig.OCPI_URL}wishlist/${this.userService.getUser().id}`, {location_id: location.id}).pipe(map(resp => {
      this.wishList.push(location);
      this.wishListSubject.next(this.wishList);
      return resp;
    }));
  }

  removeFromWishList(locationId) {
    return this.http.delete<any>(`${AppConfig.OCPI_URL}wishlist/${this.userService.getUser().id}/${locationId}`).pipe(map(resp => {
      this.wishList = this.wishList.filter(location => location.id !== locationId);
      this.wishListSubject.next(this.wishList);
      return resp;
    }));
  }

  setSelectedLocation(location) {
    this.selectedLocation = location;
  }

  getSelectedLocation() {
    return this.selectedLocation;
  }

  setSelectedConnector(connector) {
    this.selectedConnector = connector;
  }

  getSelectedConnector() {
    return this.selectedConnector;
  }

  updateWithEstimation(location, vehicle) {
    location.evses.forEach(evse => {
      evse.connectors.forEach((connector: any) => {
        if (connector.tariff_id) {
          this.fetchTariffDetails(connector.tariff_id, location.id).pipe(catchError(error => null)).subscribe(resp => {
            if (resp) {
              connector.tariff = resp.data;
              connector.estimation = this.tariffService.getEstimateForHour(connector, null);
              if (vehicle) {
                connector.primaryEstimation = this.tariffService.getEstimateForHour(connector, vehicle);
              }
            }
          });
        }
      });
    });
  }

  updateLocation(location) {
    let connectorsCount = 0;
    let totalConnectors = 0;
    let evses = [];

    location.evses.forEach(evse => {
      if (evse.connectors && evse.connectors.length) {
        if(evse.status === 'AVAILABLE') {
          connectorsCount += evse.connectors.length;
        }
        totalConnectors += evse.connectors.length;
        evse.connectors.forEach((connector: any) => {
          connector.powerInKwh = this.tariffService.getPower(connector, null);
          connector.powerTypeName = connector.power_type === 'DC' ? 'DC' : (connector.power_type === 'AC_3_PHASE' ? 'AC 3' : 'AC');
          // if (connector.tariff_id) {
          //   this.fetchTariffDetails(connector.tariff_id, location.id).pipe(catchError(error => null)).subscribe(resp => {
          //     if (resp) {
          //       connector.tariff = resp.data;
          //       connector.priceFor15mins = this.tariffService.getPrice(connector.tariff, connector.amperage, 15);
          //     }
          //   });
          // }
        });
        evses.push(evse);
      } 
    });
    location.evses = evses;
    location.totalConnectors = totalConnectors;
    location.availableConnectors = connectorsCount;

    if (location.opening_times) {
      if (location.opening_times.twentyfourseven) {
        location.isOpened = true;
        location.openTime = '00:00';
        location.closeTime = '23:59';
      } else {
        if(location.opening_times.regular_hours && location.opening_times.regular_hours.length) {
          let currentDay = new Date().getDay() || 7;
          let regularhour = location.opening_times.regular_hours.find((item) => {
            return item.weekday === currentDay;
          });
          if (regularhour) {
            location.openTime = regularhour.period_begin;
            location.closeTime = regularhour.period_end;
          }
        }
      }
      if (location.openTime) {
        const currentTime = new Date().getTime();
        const startTime = this.getTimeMills(location.openTime);
        const endTime = this.getTimeMills(location.closeTime);
        if (currentTime >= startTime && currentTime < endTime) {
          location.isOpened = true;
        }
      } 
    }
  }

  private getTimeMills(timeText: string) {
    let time = timeText.split(':');
    let date = new Date();
    date.setHours(+time[0], +time[1], 0, 0);
    return date.getTime();
  }

  updateLocations(locations) {
    locations.forEach(location => {
      this.updateLocation(location); 
    });
  }

  startSession(body: any) {
    return this.http.post<any>(`${AppConfig.OCPI_URL}commands/START_SESSION`, body);
  }

  stopSession(sessionId) {
    return this.http.post<any>(`${AppConfig.OCPI_URL}commands/STOP_SESSION`, {session_id: sessionId});
  }

  getSessionUpdate() {
    return this.http.get<any>(`${AppConfig.OCPI_URL}sessions/${this.userService.getUser().id}/latest`);
  }

  setLastSessionId(sessionId: string) {
    this.lastSessionId = sessionId;
  }

  getLastSessionId() {
    return this.lastSessionId;
  }

  getSessionDetails(sessionId: string) {
    return this.http.get<any>(`${AppConfig.OCPI_URL}sessions/${sessionId}`);
  }

  getCDRBySessionId(sessionId: string) {
    return this.http.get<any>(`${AppConfig.OCPI_URL}cdrs/session/${sessionId}`);
  }

  getLastSessionDetails() {
    return this.http.get<any>(`${AppConfig.OCPI_URL}sessions/${this.lastSessionId}`);
  }

  fetchTariffDetails(tariffId, locationId) {
    return this.http.get<any>(`${AppConfig.OCPI_URL}tariffs/location/${locationId}/${tariffId}`);
  }

  fetchCDRs() {
    return this.http.get<any>(`${AppConfig.OCPI_URL}cdrs/${this.userService.getUser().id}/search?page_no=0&page_size=50`);
  }

  getCDRDetail(cdrId: string) {
    return this.http.get<any>(`${AppConfig.OCPI_URL}cdrs/${cdrId}`);
  }

  getCDRStats(dateFrom: string, dateTo: string) {
    return this.http.get<any>(`${AppConfig.OCPI_URL}cdrs/stats/power-consumption?user_id=${this.userService.getUser().id}&date_from=${dateFrom}&date_to=${dateTo}`);
  }

  getLocationFromLocationId(countryCode, partyId, locationId) {
    return this.http.get<any>(`${AppConfig.OCPI_URL}locations/${countryCode}/${partyId}/${locationId}?apply_tariff_restrictions=true`);
  }

  getLocationFromEvse(evseId) {
    return this.http.get<any>(`${AppConfig.OCPI_URL}locations/evseid/${evseId}?apply_tariff_restrictions=true`);
  }

  setActiveSessionData(data) {
    this.activeSessionData = data;
  }

  goToActiveChargingSession(data?) {
    if (!data) {
      data = this.activeSessionData;
    }
    const evse = data.location && data.location && data.location.evses && data.location.evses.length && data.location.evses[0];
    const connector = evse && evse.connectors && evse.connectors.length && evse.connectors[0];
    if (connector) {
        this.userService.getVehicles().pipe(catchError(error => null)).subscribe((resp) => {
            let primaryVehicle;
            if (resp) {
                let vehicles = resp.data.vehicles || [];
                primaryVehicle = vehicles.find(item => !!item.is_primary);
            }
            this.fetchTariffDetails(connector.tariff_id, data.location.id).pipe(catchError(error => null)).subscribe(resp => {
                
                const estimationInfo = data && data.estimation_info;
                if (estimationInfo) {
                    connector.isPrimaryVehicle = estimationInfo.isPrimaryVehicle;
                    connector.selectedMoney = estimationInfo.selectedMoney;
                    connector.selectedTime = estimationInfo.selectedTime;
                    connector.selectedRange = estimationInfo.selectedRange;
                    connector.selectedPower = estimationInfo.selectedPower;
                    connector.isCorporateCustomer = estimationInfo.isCorporateCustomer;
                    connector.fromActiveSession = true;
                }
                if (resp) {
                  connector.tariff = resp.data;
                  connector.estimation = this.tariffService.getEstimateForHour(connector, connector.isPrimaryVehicle ? primaryVehicle : null);
                }
                this.setSelectedLocation(data.location);
                this.setSelectedConnector(connector);
                this.router.navigate(['/charging-started']);
            });
        });
    }
  }
}
