import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LocationGuard } from './guards/location.guard';

const routes: Routes = [
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    redirectTo: 'tabs',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/login/login.module').then( m => m.LoginPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'otp',
    loadChildren: () => import('./pages/auth/otp/otp.module').then( m => m.OtpPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/auth/signup/signup.module').then( m => m.SignupPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'phone-number-enter',
    loadChildren: () => import('./pages/auth/phone-number-enter/phone-number-enter.module').then( m => m.PhoneNumberEnterPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'wallet',
    loadChildren: () => import('./pages/billing/wallet/wallet.module').then( m => m.WalletPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'add-money',
    loadChildren: () => import('./pages/billing/add-money/add-money.module').then( m => m.AddMoneyPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'payment-modes',
    loadChildren: () => import('./pages/billing/payment-modes/payment-modes.module').then(m => m.PaymentModesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'netbanking',
    loadChildren: () => import('./pages/billing/netbanking/netbanking.module').then(m => m.NetbankingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile/profile.module').then( m => m.ProfilePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'support',
    loadChildren: () => import('./pages/other/support/support.module').then( m => m.SupportPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'charging-details/:locationId',
    loadChildren: () => import('./pages/sessions/charging-details/charging-details.module').then( m => m.ChargingDetailsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'initiate-charging',
    loadChildren: () => import('./pages/sessions/initiate-charging/initiate-charging.module').then( m => m.InitiateChargingPageModule),
    canActivate: [AuthGuard, LocationGuard]
  },
  {
    path: 'estimate-money',
    loadChildren: () => import('./pages/sessions/estimate-money/estimate-money.module').then( m => m.EstimateMoneyPageModule),
    canActivate: [AuthGuard, LocationGuard]
  },
  {
    path: 'estimate-amount',
    loadChildren: () => import('./pages/sessions/estimate-amount/estimate-amount.module').then( m => m.EstimateAmountPageModule),
    canActivate: [AuthGuard, LocationGuard]
  },
  {
    path: 'history',
    loadChildren: () => import('./pages/other/history/history.module').then( m => m.HistoryPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'billsummary/:id',
    loadChildren: () => import('./pages/billing/billsummary/billsummary.module').then( m => m.BillsummaryPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'faq',
    loadChildren: () => import('./pages/other/faq/faq.module').then( m => m.FaqPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'rating',
    loadChildren: () => import('./pages/other/rating/rating.module').then( m => m.RatingPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'cpo-filter',
    loadChildren: () => import('./pages/sessions/cpo-filter/cpo-filter.module').then( m => m.CpoFilterPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'charging-started',
    loadChildren: () => import('./pages/sessions/charging-started/charging-started.module').then( m => m.ChargingStartedPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'charging-proceed',
    loadChildren: () => import('./pages/sessions/charging-proceed/charging-proceed.module').then( m => m.ChargingProceedPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'my-vehicle',
    loadChildren: () => import('./pages/profile/my-vehicle/my-vehicle.module').then( m => m.MyVehiclePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'add-vehicle',
    loadChildren: () => import('./pages/profile/add-vehicle/add-vehicle.module').then( m => m.AddVehiclePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'add-vehicle/:id',
    loadChildren: () => import('./pages/profile/add-vehicle/add-vehicle.module').then( m => m.AddVehiclePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'my-usage',
    loadChildren: () => import('./pages/profile/my-usage/my-usage.module').then( m => m.MyUsagePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'create-ticket',
    loadChildren: () => import('./pages/other/create-ticket/create-ticket.module').then( m => m.CreateTicketPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'tou',
    loadChildren: () => import('./pages/other/tou/tou.module').then( m => m.TouModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./pages/other/privacy/privacy.module').then( m => m.PrivacyModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pages/sessions/notifications/notifications.module').then( m => m.NotificationsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'social-login',
    loadChildren: () => import('./pages/auth/social-login/social-login.module').then( m => m.SocialLoginPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
