import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SimpleResponse } from '../models/authentication';
import { User } from '../models/user';
import { Vehicle } from '../models/vehicle';
import { AppConfig } from '../shared/app-config';
import { TokenService } from '../shared/token.service';
import { AppCommonService } from './app-common.service';

@Injectable({
  providedIn: 'root'
})
export class UserService implements OnDestroy {
  otpType = '';
  tokenDetails: any;
  token: any;
  user: User; 

  vehicleUpdateSubject: Subject<boolean> = new Subject();

  userSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private router: Router,
    private http: HttpClient, 
    private appCommonService: AppCommonService,
    private navCtrl: NavController,
    private tokenService: TokenService) { 
  }

  ngOnDestroy(): void {
    this.userSubject.next(null);
    this.userSubject.complete();

    this.vehicleUpdateSubject.next(false);
    this.vehicleUpdateSubject.complete();
  }

  currentUser$() : Observable<any> {
    return this.userSubject.asObservable();
  }

  getUser() {
    return this.user;
  }

  logout() {
    this.tokenService.removeToken();
    this.userSubject.next(null);
    this.navCtrl.navigateRoot('login');
  }

  getOTP(phoneNumber: string): Observable<SimpleResponse> {
    this.otpType = this.appCommonService.isSignInFlow() ? 'SU' : 'LG';
    return this.http.get<SimpleResponse>(`${AppConfig.DRIVER_URL}/otp/DRIVER/${phoneNumber}/send?otpType=${this.otpType}`);
  }

  getOTPExpiryTime(phoneNumber: string): Observable<SimpleResponse> {
    return this.http.get<SimpleResponse>(`${AppConfig.DRIVER_URL}/otp/DRIVER/${phoneNumber}/expiry-time?otpType=CCS`);
  }

  verifyOTP(mobile: string, otp: string, CCS: any): Observable<any> {
    return this.http.post<any>(`${AppConfig.DRIVER_URL}/otp/DRIVER/${mobile}/verify`, {
      mobile,
      otp,
      userType: 'DRIVER',
      type: CCS ? 'CCS' : this.otpType
    });
  }

  getUserInfo(resp: any): Observable<any> {
    resp = JSON.parse(resp);
    this.tokenService.saveToken(resp.access_token);
    this.tokenService.saveRefreshToken(resp.refresh_token);

    this.token = resp;
    return this.fetchUser();
  }

  fetchUser(): Observable<any> {
    const accessToken = this.tokenService.getToken();
    const info = this.parseJwt(accessToken);
    this.tokenDetails = info;
    if (this.tokenDetails) {
      return this.fetchUserByUserName();
    } else {
      return of(null);
    }
  }

  fetchUserByUserName(): Observable<any> {
    let user = this.tokenDetails.user_name.split(':');
    return this.http.get<any>(`${AppConfig.USER_URL}find-by-username/DRIVER/${user[0]}`)
      .pipe(catchError(error => {return of(null)}), map((resp) => {
        this.user = resp && resp.data;
        this.userSubject.next(this.user);
        return this.user;
      }));
  }

  updatePushToken(id, token) {
    return this.http.patch<any>(`${AppConfig.USER_URL}${this.user.id}/${token}`, {});
  }

  getUserDetails() {
    return this.http.get<any>(`${AppConfig.USER_URL}${this.user.id}`);
  }

  updateUser(name: string, email: string, gst_in: string = '') {
    return this.http.patch<any>(`${AppConfig.USER_URL}${this.user.id}`, {
      name,
      email,
      gst_in
    });
  }

  addVehicle(vehicleInfo: Vehicle) {
    return this.http.post<any>(`${AppConfig.DRIVER_URL}/${this.user.id}/vehicle`, vehicleInfo);
  }

  updateVehicle(vehicleId: string, vehicleInfo: Vehicle) {
    return this.http.put<any>(`${AppConfig.DRIVER_URL}/${this.user.id}/vehicle/${vehicleId}`, vehicleInfo);
  }

  getVehicle(vehicleId: string) {
    return this.http.get<any>(`${AppConfig.DRIVER_URL}/${this.user.id}/vehicle/${vehicleId}`);
  }

  getVehicles() {
    return this.http.get<any>(`${AppConfig.DRIVER_URL}/${this.user.id}/vehicle`);
  }

  deleteVehicle(vehicleId: string) {
    return this.http.delete<any>(`${AppConfig.DRIVER_URL}/${this.user.id}/vehicle/${vehicleId}`);
  }

  getVehicleOptions(option) {
    return this.http.get<any>(`${AppConfig.ADMIN_URL}options/${option}`);
  }

  getVehicleOptionsWithParent(option, parent) {
    return this.http.get<any>(`${AppConfig.ADMIN_URL}options/${option}/${parent}`);
  }

  getVehicleDetails(make, model) {
    return this.http.get<any>(`${AppConfig.ADMIN_URL}vehicles/${make}/${model}`);
  }

  private parseJwt(token) {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };

  updateVehicles() {
    this.vehicleUpdateSubject.next(true);
  }

  getVehiclesUpdateStatus() {
    return this.vehicleUpdateSubject.asObservable();
  }

  getEMSPConfiguration() {
    return this.http.get<any>(`${AppConfig.EMSP_CONFIG_URL}`);
  }

  getCPOPriceDetatils(partnerID) {
    return this.http.get<any>(`${AppConfig.ADMIN_URL}/partners/${partnerID}`);
  }

  getEMSPPriceDetatils(orgId?) {
    if (!orgId) {
      orgId = 'e925731ff7b70acde478591f1327bd1b';
    }
    return this.http.get<any>(`${AppConfig.ADMIN_URL}/bill-info/${orgId}`);
  }

  getSocialLogin() {
    return this.http.get<any>(`${AppConfig.SOCIAL_LOGIN_URL}`);
  }
}
