import { Injectable, OnDestroy } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable, of, Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { UserService } from "../services/user.service";


@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, OnDestroy {

    currentUser: any;
    unsubcribe = new Subject<void>();

    logoutPaths = ['/login', '/signup', '/phone-number-enter'];

    constructor(private userService: UserService, private router: Router) {
        this.userService.currentUser$().pipe(takeUntil(this.unsubcribe)).subscribe(user => {
            this.currentUser = user;
        });
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        const isLogoutPath = this.logoutPaths.indexOf(state.url) > -1 || state.url.indexOf('/otp?') > -1;
        if (this.currentUser) {
            if(isLogoutPath) {
                this.router.navigate(['tabs']);
                return of(false);
            } else {
                return of(true);
            }
        } 
        return this.userService.fetchUser().pipe(map((user) => {
            let hasValidUder = !!user;
            if (isLogoutPath) {
                if (hasValidUder) {
                    this.router.navigate(['tabs']);
                    return false;
                } else {
                    return true;
                }
            } else if (hasValidUder) {
                return true;
            }
            this.router.navigate(['login']);
            return false;
        }));
    }

    ngOnDestroy(): void {
        this.unsubcribe.next();
        this.unsubcribe.complete();
    }

}